
export const Animation={
    in:{
        opacity:1
    },
    out:{
     opacity:0
    }
}


export const transition ={
    duration:0.4
}