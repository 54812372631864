
import React from 'react'

import {
    SectionHome
} from './styled'

function index({frase}) {
    return (
<SectionHome>
 
    <p>{frase}</p>
</SectionHome>
    )
}

export default index
