import styled from 'styled-components'


export const WhatsappWrapper = styled.div`
    position: fixed;
    right:-2%;
    bottom: 1%;
    z-index: 10000;
    ._1bpcM{
        background-color:black;
        color:white
    }
`